@import "../index.scss";

/* menu */

.foodMenuContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10rem 0;
  padding: 10rem;
  background-color: var(--white);
  border-radius: 5rem;

  @include respond(phone) {
    padding: 3rem;
  }

  .foodMenuContainerHeader {
    font-size: 3.5rem;
    color: var(--primary-color);
    margin: 3rem 0;

    @include respond(phone) {
      font-size: 2.5rem;
      margin: 2rem 0;
    }
  }

  .foodMenuContainerList {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .foodMenuContainerItem {
      font-size: 1.6rem;
      border: 0.2rem solid var(--primary-color);
      border-radius: 1.5rem;
      padding: 1rem 2rem;
      color: var(--grey);
      margin-right: 2rem;
      margin-bottom: 2rem;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      @include respond(phone) {
        font-size: 1.4rem;
        padding: 0.8rem 1.6rem;
        margin-bottom: 1rem;
      }

      &:hover {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }
  }

  .foodMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    .foodMenuDetails {
      width: 20rem;
      height: 35rem;
      margin-right: 1rem;
      overflow: hidden;
      border-radius: 3rem;
      box-shadow: 0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
      margin-bottom: 3rem;
      text-align: center;

      @include respond(phone) {
        min-width: none;
        width: 40%;
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0rem 0.125rem 0.25rem 0rem rgba(14, 30, 37, 0.12),
          0rem 0.125rem 1rem 0rem rgba(14, 30, 37, 0.32);
      }

      .foodMenuImg {
        height: 50%;
        width: 100%;
        border-radius: 3rem;
        overflow: hidden;

        & img {
          height: 100%;
          width: 100%;
          transition: 0.4s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }

      & h1 {
        padding: 2rem;
        font-size: 1.8rem;
        color: var(--primary-color);
      }

      & p {
        font-size: 2rem;
        color: var(--grey);
        margin-top: 1rem;
      }
    }
  }
}
