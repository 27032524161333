@import "../index.scss";

/* about */
.about {
  height: 100vh;

  .aboutContainer {
    width: 100%;
    margin: auto;
    height: 100vh;
    padding: 5rem;
    display: flex;
    align-items: center;

    @include respond(phone) {
      padding: 2rem;
      position: relative;
    }

    .aboutContainerImg {
      width: 60%;
      height: 70%;
      border-radius: 3rem;

      @include respond(phone) {
        width: 100%;
        height: 90%;
      }
    }

    .aboutContainerDetails {
      width: 40%;
      text-align: center;
      background-color: var(--white);
      border-radius: 3rem;
      transform: translateX(-10rem);
      box-shadow: 0rem 0.44rem 1.81rem 0rem rgba(100, 100, 111, 0.2);

      @include respond(phone) {
        position: absolute;
        top: 7rem;
        right: -6rem;
      }

      & h1 {
        font-size: 3rem;
        color: var(--primary-color);
        margin: 5rem 3rem 2rem 3rem;

        @include respond(phone) {
          font-size: 2rem;
        }
      }

      & p {
        font-size: 1.7rem;
        color: var(--grey);
        margin: 5rem 3rem 2rem 3rem;

        @include respond(phone) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
