@import '../../index.scss';

.contactForm {
  width: 40%;
  padding: 3rem 2rem;
  background-color: var(--white);
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem grey;

  @include respond(phone){
    width: 70%;
  }

  & input {
    font-size: 1.6rem;
    color: var(--grey);
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    margin-bottom: 2rem;
  }

  & textarea {
    font-size: 1.6rem;
    color: var(--grey);
    padding: 1rem 1.5rem;
    outline: none;
    border: none;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    resize: none;
    margin-bottom: 2rem;
  }

  & button {
    font-size: 1.6rem;
    padding: 0.8rem 1rem;
    outline: none;
    border: none;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--white);
    cursor: pointer;

    &:hover {
      background-color: #088347;
    }
  }
}
