@import "../index.scss";

/* home */
.home {
  height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 15;

  .homeVideoIntro {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .homeDetails {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
  
    .slogan {
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 20vh;
  
      & h1 {
        font-size: clamp(1.5rem, 7vw, 5rem);
        letter-spacing: 0.2rem;
        color: white;
        text-transform: uppercase;
        margin: 0 20px;
        word-wrap: break-word;
  
        @include respond(phone) {
          font-size: clamp(1.5rem, 7vw, 3rem);
          letter-spacing: 0.1rem;
        }
      }
  
      & p {
        font-size: clamp(1rem, 4vw, 2rem);
        color: var(--white);
        margin-bottom: 2rem;
  
        @include respond(phone) {
          font-size: clamp(0.8rem, 3vw, 1.5rem);
        }
      }
  
      & button {
        font-size: clamp(1rem, 4vw, 2rem);
        color: var(--white);
        background-color: transparent;
        outline: none;
        border: 0.3rem solid var(--primary-color);
        border-radius: 3rem;
        padding: 0.8rem 2rem;
        cursor: pointer;
        transition: 0.3s ease-in-out;
  
        @include respond(phone) {
          font-size: clamp(0.8rem, 3vw, 1.5rem);
          border-radius: 3rem;
          padding: 0.6rem 1.5rem;
        }
  
        &:hover {
          background-color: var(--primary-color);
        }
      }
    }
  }  
}
